import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Overlay,
  Popover,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import {
  asyncActivePhoneNumberList,
  calculateMessageSegments,
  customSelectTheme,
  removeEmptyAndNullValues,
  styleObj,
} from "helpers/common";
import { openModal, sendSMSThunk, updateUserStepsThunk } from "slices/thunk";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createSelector } from "reselect";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import Tooltip from "rc-tooltip";
import EmojiPicker from "emoji-picker-react";

interface FormData {
  apiKey: string;
  publicKey: string;
}

const SendSMS = (props: any) => {
  document.title = "Signal House Portal Send an SMS";
  const dispatch = useDispatch<any>();

  const selectAccount = createSelector(
    (state: any) => state.AuthUser || {},
    (state: any) => state.Login || {},
    (authUser, login) => ({
      stepsData: authUser.stepsData,
      user: authUser.authUser,
      loginData: login.loginData,
    })
  );

  const { stepsData, user, loginData } = useSelector(selectAccount);
  const messagingData: any = useSelector((state: any) => state.Messages);
  const phoneRegExp = /^\d{1,3}\d{4,14}$/;
  const urlRegExp =
    /((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/g;

  const [isGroupMessaging, setIsGroupMessaging] = useState(false);
  const [isShowShortLink, setIsShowShortLink] = useState(false);
  const [isShortLink, setIsShortLink] = useState(false);
  const [fromTemp, setFromTemp] = useState<any>({});
  const [formData, setFormData] = useState<FormData>({
    apiKey: "",
    publicKey: "",
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiButtonRef = React.useRef(null);
  const emojiPickerRef = React.useRef(null);

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      from: "",
      to: [],
      statusCallBackUrl: "",
      body: "",
      groupPhoneNumbers: [],
    },
    validationSchema: Yup.object({
      from: Yup.string().required("Please Enter Phone Number"),
      to: Yup.array()
        .of(
          Yup.string()
            .matches(
              phoneRegExp,
              "Phone number must include 1 to 3 digits for the country code followed by 4 to 14 digits for the number"
            )
            .required("Please Enter To Phone Number")
        )
        .test(
          "attachments-or-mediaUrls",
          "Please Enter to phone number",
          function (value) {
            if (value && value.length > 0) {
              return true;
            }
            return this.createError({
              message: "Please Add To Phone Number",
            });
          }
        ),
      statusCallBackUrl: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_%#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .notRequired(),
      body: Yup.string().min(5).max(1600).required("Please Enter Message"),
    }),
    onSubmit: (values) => {
      const payload = isGroupMessaging
        ? {
            from: values.from,
            body: values.body,
            groupPhoneNumbers: values.to?.map((dt: any) => dt.toString()),
            statusCallBackUrl: values.statusCallBackUrl,
            shortLink: isShortLink,
          }
        : {
            from: values.from,
            to: values.to?.map((dt: any) => dt.toString()),
            statusCallBackUrl: values.statusCallBackUrl,
            body: values.body,
            shortLink: isShortLink,
          };
      dispatch(sendSMSThunk(removeEmptyAndNullValues(payload), () => cb()));
    },
  });

  useEffect(() => {
    const obj = loginData && JSON.parse(loginData);
    obj?.apiKey && setFormData((prev) => ({ ...prev, apiKey: obj?.apiKey }));
    obj?.authToken &&
      setFormData((prev) => ({ ...prev, publicKey: obj?.authToken }));
  }, [loginData]);

  useEffect(() => {
    if (validation.values.body) {
      const temp = urlRegExp.test(validation.values.body);
      setIsShowShortLink(temp);
      !temp && setIsShortLink(false);
    }
  }, [validation.values.body]);

  const cb = () => {
    validation.resetForm();
    validation.setFieldValue("from", fromTemp?.phoneNumber || "");
    !stepsData?.sendFirstSms &&
      dispatch(
        updateUserStepsThunk({ sendFirstSms: true }, user?.completedSteps)
      );
  };

  const copyText = (e: any, type: string) => {
    e && e.preventDefault();
    const textToCopy = document.getElementById(type) as HTMLInputElement;
    if (textToCopy) {
      const textArea = document.createElement("textarea");
      textArea.id = "copyDiv";
      textArea.style.position = "absolute";
      textArea.style.bottom = "0px";
      textArea.textContent = textToCopy.textContent;
      document.body.append(textArea);
      textArea.select();
      textArea.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(textArea?.value);
      toast.success("Text copied");
      const myDiv = document.getElementById("copyDiv") as HTMLInputElement;
      myDiv.remove();
    }
  };

  const createCurl = () => {
    let string = "";
    if (validation.values.from)
      string += `\n"from": "${validation.values.from}",`;
    if (validation.values.body)
      string += `\n"body": "${validation.values.body}",`;
    if (validation.values.statusCallBackUrl)
      string += `\n"statusCallBackUrl": "${validation.values.statusCallBackUrl}",`;
    if (isGroupMessaging) {
      if (validation.values?.to?.length > 0)
        string += `\n"groupPhoneNumbers": [${validation.values.to?.map(
          (dt: string, i: number) =>
            `\n"${dt}"${i === validation.values.to.length - 1 ? "\n" : ""}`
        )}],`;
    } else {
      if (validation.values?.to?.length > 0)
        string += `\n"to": [${validation.values.to?.map(
          (dt: string, i: number) =>
            `\n"${dt}"${i === validation.values.to.length - 1 ? "\n" : ""}`
        )}],`;
    }
    string += `\n"shortLink": ${isShortLink},`;

    string = string.substring(0, string.length - 1);
    return string;
  };

  const handleEmojiClick = (emojiData: any) => {
    validation.setFieldValue(
      "body",
      (validation.values.body || "") + emojiData.emoji
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        emojiPickerRef.current &&
        !(emojiPickerRef.current as any).contains(event.target) &&
        !(emojiButtonRef.current as any).contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Send an SMS" />

          <div className="position-relative">
            <Card className="wrapper">
              <Card.Body>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                >
                  <Row>
                    <Col xl={6}>
                      <h6 className="card-title mb-3">
                        Step 1: Recipients and Senders
                      </h6>
                      <div className="mb-3">
                        <Form.Label htmlFor="from">
                          From phone number{" "}
                          <span className="text-primary">*</span>
                        </Form.Label>
                        <AsyncPaginate
                          key="from"
                          styles={styleObj(
                            validation?.touched?.from &&
                              validation?.errors?.from
                          )}
                          theme={customSelectTheme}
                          value={fromTemp || {}}
                          loadOptions={asyncActivePhoneNumberList(
                            removeEmptyAndNullValues({
                              tenDLCStatus: "Ready",
                              numberStatus: "Active",
                            }),
                            "phoneNumber"
                          )}
                          getOptionValue={(option: any) => option?.phoneNumber}
                          getOptionLabel={(option: any) => option?.phoneNumber}
                          onChange={(option: any) => {
                            setFromTemp(option);
                            validation.setFieldValue(
                              "from",
                              option.phoneNumber
                            );
                          }}
                          isSearchable={true}
                          additional={{
                            page: 1,
                          }}
                        />
                        {validation.touched.from && validation.errors.from ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation?.errors?.from}
                          </Form.Control.Feedback>
                        ) : null}
                      </div>
                      <div
                        className="form-check form-switch form-switch-md"
                        dir="ltr"
                      >
                        <Form.Check
                          type="checkbox"
                          role="switch"
                          label="Group Messaging"
                          checked={isGroupMessaging}
                          onChange={() =>
                            setIsGroupMessaging(!isGroupMessaging)
                          }
                          style={{
                            marginBottom: "20px",
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Label
                          htmlFor="groupPhoneNumbers"
                          className="mb-2"
                        >
                          {isGroupMessaging
                            ? "Group phone numbers"
                            : "To phone number"}{" "}
                          <span className="text-primary">*</span>
                        </Form.Label>
                        <Button
                          variant="secondary"
                          className="d-flex align-items-center"
                          onClick={() => {
                            dispatch(
                              openModal({
                                path: "AddPhoneNumber",
                                data: {
                                  title: "Add Phone Number",
                                  phoneArr: validation.values.to,
                                  setPhone: (data: any) =>
                                    validation.setFieldValue("to", data),
                                  footer: false,
                                },
                              })
                            );
                          }}
                        >
                          <i className="bx bx-plus me-1"></i> Add Phone Number
                        </Button>
                        {validation.touched.to && validation.errors.to && (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {validation.errors.to}
                          </Form.Control.Feedback>
                        )}
                        <ul className="hstack gap-2 list-unstyled my-3 flex-wrap">
                          {validation.values.to?.map(
                            (dt: string, i: number) => (
                              <li key={i}>
                                <div
                                  className="d-block p-2 bg-light bg-opacity-50 rounded"
                                  style={{ width: "max-content" }}
                                >
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="avatar-xs flex-shrink-0 ronded">
                                      <div className="avatar-title bg-body-secondary text-body">
                                        <i className="ri ri-phone-line fs-lg"></i>
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      <h6 className="mb-0 text-break">
                                        {dt || ""}
                                      </h6>
                                    </div>
                                    <div className="flex-shrink-0 text-muted">
                                      <i
                                        className="ri-close-circle-fill cursor-pointer fs-2xl text-secondary ms-3"
                                        onClick={() => {
                                          const temp = [
                                            ...validation.values.to,
                                          ];
                                          temp.splice(i, 1);
                                          validation.setFieldValue("to", temp);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      <div className="mb-3">
                        <Form.Label
                          htmlFor="statusCallBackUrl"
                          className="position-relative"
                        >
                          Status Call-Back URL{" "}
                          <Tooltip
                            placement="bottom"
                            overlay={
                              "A webhook URL for a success or failure notification concerning this message."
                            }
                          >
                            <i
                              className="ri-information-line text-secondary fs-2xl ms-2 position-absolute"
                              style={{ bottom: -2 }}
                            />
                          </Tooltip>
                        </Form.Label>
                        <Form.Control
                          type="url"
                          name="statusCallBackUrl"
                          onChange={validation?.handleChange}
                          value={validation.values.statusCallBackUrl}
                          isInvalid={
                            validation?.touched?.statusCallBackUrl &&
                            validation?.errors?.statusCallBackUrl
                              ? true
                              : false
                          }
                        />
                        {validation.touched.statusCallBackUrl &&
                        validation.errors.statusCallBackUrl ? (
                          <Form.Control.Feedback type="invalid">
                            {validation?.errors?.statusCallBackUrl}
                          </Form.Control.Feedback>
                        ) : null}
                      </div>

                      <h6 className="card-title mb-3 mt-4">
                        Step 2: Sending messages
                      </h6>
                      <div className="mb-3 position-relative">
                        <Form.Label htmlFor="body">
                          Message <span className="text-primary">*</span>
                        </Form.Label>
                        <div className="position-relative">
                          <textarea
                            id="body"
                            name="body"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.body || ""}
                            className={`form-control ${
                              validation.touched?.body &&
                              validation.errors?.body
                                ? "is-invalid"
                                : ""
                            }`}
                            rows={3}
                            minLength={5}
                            style={{ resize: "none", paddingRight: 30 }}
                          />
                          <Button
                            ref={emojiButtonRef}
                            variant="link"
                            className="position-absolute"
                            style={{ bottom: 0, right: 5, padding: 0 }}
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                          >
                            <i className="ri-emotion-line fs-4xl" />
                          </Button>
                        </div>

                        <Overlay
                          show={showEmojiPicker}
                          target={emojiButtonRef.current}
                          placement="top-end"
                          container={document.body}
                        >
                          {(props) => (
                            <Popover
                              id="emoji-picker-popover"
                              {...props}
                              style={{
                                ...props.style,
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                            >
                              <div ref={emojiPickerRef}>
                                <EmojiPicker
                                  onEmojiClick={handleEmojiClick}
                                  width={350}
                                  height={400}
                                  lazyLoadEmojis={true}
                                  previewConfig={{ showPreview: false }}
                                />
                              </div>
                            </Popover>
                          )}
                        </Overlay>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            {validation.touched?.body &&
                            validation.errors?.body ? (
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-block"
                              >
                                {validation.errors?.body}
                              </Form.Control.Feedback>
                            ) : null}
                          </div>
                          <div className="">
                            Segments:{" "}
                            {
                              calculateMessageSegments(validation.values.body)
                                ?.segments
                            }{" "}
                            Length: {validation.values.body?.length}
                          </div>
                        </div>
                      </div>

                      {isShowShortLink && (
                        <div
                          className="form-check form-switch form-switch-md"
                          dir="ltr"
                        >
                          <Form.Check
                            type="checkbox"
                            role="switch"
                            label="URL Shortener"
                            checked={isShortLink}
                            onChange={() => setIsShortLink(!isShortLink)}
                            style={{
                              marginBottom: "20px",
                            }}
                          />
                        </div>
                      )}
                    </Col>

                    <Col xl={6}>
                      <Card className="mb-4">
                        <Card.Header
                          style={{
                            background: "rgb(31, 48, 76)",
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                          }}
                        >
                          <h6 className="fs-md text-white">Request</h6>
                        </Card.Header>
                        <Card.Body
                          className="text-white position-relative"
                          style={{
                            background: "rgb(18, 28, 45)",
                            borderBottomLeftRadius: 10,
                            borderBottomRightRadius: 10,
                          }}
                        >
                          <div
                            className="position-absolute end-0 me-3 cursor-pointer"
                            onClick={(e) => copyText(e, "request")}
                          >
                            <i className="bx bx-copy align-middle me-2 fs-3xl" />
                          </div>
                          <div id="request">
                            <pre className="fs-sm">
                              {`curl -X 'POST' \\
  ${`${
    process.env.REACT_APP_BACKEND_URL || "https://devapi.signalhouse.io"
  }/message/sendSMS`} \\
  -H 'accept: application/json' \\
  -H 'apiKey: ${formData.apiKey}' \\
  -H 'authToken: ${formData.publicKey}' \\
  -H 'Content-Type: application/json' \\
  -d '{${createCurl()}
}'`}
                            </pre>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <div className="hstack gap-2">
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        validation.resetForm();
                        validation.setFieldValue(
                          "from",
                          fromTemp?.phoneNumber || ""
                        );
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={messagingData?.loading}
                    >
                      {messagingData?.loading && (
                        <Spinner size="sm" animation="border" />
                      )}{" "}
                      Send Test Message
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SendSMS);
